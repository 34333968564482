<template>
  <div class="container-1">
    <div class="title">
      <img @click="back" src="../assets/images/back1.png" alt="" />
      <input
        type="text"
        class="search"
        v-model="value"
        placeholder="搜索专家 医院或治疗信息"
      />
    </div>
    <div class="nav" v-for="item in list" :key="item.id">
      <div class="tou">
        <div class="nav-left">
          <img :src="item.avatar" alt="" />
        </div>
        <div class="nav-middle">
          <span class="nav-middle-title">{{ item.username }}</span>
          <!-- <span class="nav-middle-title1">{{ item.hour }}</span> -->
          <!-- <span class="nav-middle-title">这里是用户名</span> -->
          <span class="nav-middle-title1">注册于一小时前</span>
        </div>
        <div class="nav-right">
          <button class="btn" @click="upload">上传医师执照</button>
        </div>
      </div>
      <div class="dibu">
        <div>
          <p>9999</p>
          <span>回答</span>
        </div>
        <div>
          <p>9999</p>
          <span>文章</span>
        </div>
        <div>
          <p>9999</p>
          <span>关注</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      list: []
    }
  },
  created() {
    const detail = [JSON.parse(localStorage.getItem('to'))]
    this.list = detail
    // console.log(detail)
    // detail.forEach(e => {
    //   console.log(e)
    // })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    upload() {
      this.$router.push({
        name: 'upload'
      })
    }
  }
}
</script>

<style scoped>
.container-1 {
  width: 100%;
  height: 800px;
  background: #f9f9f9;
}
.title {
  width: 100%;
  height: 120px;
  background: #38a6ff;
  display: flex;
  align-items: center;
}
.container-1 .title img {
  margin-left: 1%;
  height: 30px;
  width: 30px;
  margin-bottom: 31px;
}
.search {
  width: 230px;
  height: 30px;
  background: #ffffff;
  border-radius: 17px;
  border: 0;
  margin-left: 10%;
  padding-left: 3%;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-bottom: 31px;
}
.nav {
  width: 94%;
  height: 177px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 11%;
  left: 3%;
}
.tou {
  display: flex;
  height: 80px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-around;
}
.nav-left {
  width: 70px;
  height: 70px;
  margin-left: 3%;
}
.nav-left img {
  display: block;
  width: 100%;
  height: 100%;
}
.nav-middle {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
.nav-middle-title {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-bottom: 3%;
}

.nav-middle-title1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  /* line-height: 20px; */
}
.btn {
  width: 130px;
  height: 40px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid;
  color: #ffffff;
  background: #38a6ff;
  margin-bottom: 9%;
  font-size: 16px;
}
.dibu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dibu p {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666;
}
.dibu span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
</style>
